<template>
  <v-navigation-drawer
    :loading="true"
    app
    clipped
    class="accent-4"
    color="primary"
    dark
    :mini-variant="drawer"
    mini-variant-width="56"
    :expand-on-hover="drawer"
    :permanent="drawer"
    width="250"
  >
    <v-list
      nav
      dense
    >
      <v-list-item-group
        v-model="selectedMenuItem"
        color="secundary"
        mandatory
      >
        <v-list-item
          v-for="item in items"
          :key="item.mainPath"
          link
          v-bind:class="{ active: (item.mainPath === $route.meta.mainPath || (environment === 'dviewcontent' && mp === item.mainPath)) ? true : false }"
          @click="handleGoTo(item.mainPath, item.path)"
        >
          <v-list-item-icon>
            <v-badge
              v-if="item.BadgeContent"
              color="red accent-4"
              icon="mdi-vuetify"
              :content="item.BadgeContent"
            >
              <v-icon color="tomato" >{{ item.icon }}</v-icon>
            </v-badge>
            <v-icon v-else color="tomato" >{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ isMenuTranslate && item.title.indexOf('.') >= 0 ? $t(item.title, locale) : item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          mandatory
        >
          <v-list-item
            link
            v-bind:class="{ active: false }"
            @click="handleGoTo('login', '/login')"
          >
            <v-list-item-icon>
              <v-icon >mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t('sidebar.logout', locale) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>

    <!-- <template v-slot:append>
      <div class="pa-2">
        <v-btn
          v-if="drawer"
          block
          @click="handleGoTo('login', 'login')"
          small
        >          
          <span>{{$t('sidebar.logout', locale)}}</span>
        </v-btn>
        <v-icon
          v-else
          dark
        >
          mdi-cloud-upload
        </v-icon>
      </div>
    </template> -->
  </v-navigation-drawer>
</template>

<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import utils from '@/services/utils'
  export default {
    props: {
      showDrawer: {
        type: Boolean,
        require: true
      }
    },
    data () {
      return {
        drawer: true,
        selectedMenuItem: 0,
        items: [],
        sidebarBadgesData: [],
        isMenuTranslate: false,
        environment: null,
        mp: null,
      }
    },
    computed: {
      ...mapState('app',['mainPath', 'locale', 'dviewcontentWorkspace', 'dtouchWorkspace',  'bookingWorkspace', 'dfilesWorkspace', 'queueWorkspace', 'turismoWorkspace', 'playersWorkspace', 'sidebarBadges']),
      actualWorkspace () {
        return this.$store.state.app[`${this.environment}Workspace`]
      },
      isMobile () {
        return utils.isMobile()
      },
    },
    watch: {
      sidebarBadges () {
        this.prepareSidebarBadges() 
        this.prepareBadges()
      },
      'dviewcontentWorkspace' (v) {
        if(v) this.handleGetSidebarItems()
      },
      'dtouchWorkspace' (v) {
        if(v) this.handleGetSidebarItems()
      },
      'bookingWorkspace' (v) {
        if(v) this.handleGetSidebarItems()
      },
      'dfilesWorkspace' (v) {
        if(v) this.handleGetSidebarItems()
      },
      'turismoWorkspace' (v) {
        if(v) this.handleGetSidebarItems()
      },
      'queueWorkspace' (v) {
        if(v) this.handleGetSidebarItems()
      },
      'playersWorkspace' (v) {
        if(v) this.handleGetSidebarItems()
      },
      '$route' () {
        this.selectedMenuItem = this.items.findIndex(x => x.mainPath === this.$route.meta.mainPath) // select menu with actual rute
      },
      '$route.meta.environment' () {
        this.handleChangeEnvironment ()
      },
      '$route.meta.mainPath' (v) {
        if (this.environment === 'booking') {
          // TODO check sec
          const is403 = this.items.length > 0 && this.items.filter(x => x.mainPath===v).length === 0
          if (is403) window.location.href = '/#/login'
        }
        
        this.selectActiveMenu() // select menu with actual rute
      },
      showDrawer (v) {
        if (this.isMobile) return
        this.$store.state.app.drawer = v
        this.drawer = v
      },
      mainPath () {
        if (this.environment === 'dviewcontent') this.mp = this.$store.state.app.mainPath
      },
    },
    mounted () {
      this.mp = this.$store.state.app.mainPath
      this.prepareSidebarBadges() 
      this.$store.state.app.drawer = this.isMobile ? true : this.showDrawer
      this.actualMainPath = this.$route.meta.mainPath
      this.handleChangeEnvironment ()
    },
    methods: {
      prepareSidebarBadges () {
        this.sidebarBadgesData = this.$store.state.app.sidebarBadges.filter(x => x.environment === 'booking')
      },
      prepareBadges () {
        const sidebarItem = JSON.parse(JSON.stringify(this.items))
        for (const item of sidebarItem) {
          const aux = this.sidebarBadgesData.filter(x => x.name === item.name).shift()
          item.BadgeContent = aux && aux.content > 0 ? aux.content : null
        }
        this.items = JSON.parse(JSON.stringify(sidebarItem))
      },
      handleChangeEnvironment () {
        this.environment = this.$route.meta.environment === 'sys' ? 'system' : this.$route.meta.environment
        this.handleGetSidebarItems()
      },
      handleGetSidebarItems () {
        this.items = []

        if(!this.actualWorkspace && this.environment !== 'system' && this.environment !== 'players' && this.environment !== 'status' && this.environment !== 'status2' ) return // if not system environment the workspace is required

        this.isMenuTranslate = this.environment !== 'dviewcontent'

        const url = this.environment === 'status2' || this.environment === 'status' ? `v1/internal/constants/sidebar/${this.environment}`
                  : this.environment === 'system'
                    ? `v1/internal/users/${utils.getUser()}/sidebar`
                    : `v1/internal/workspaces/${this.actualWorkspace}/sidebar`

        console.log('aaa', url)

        api.getAllWithoutLimit (this.environment, url)
          .then(response => {
            this.items = response.filter(x => x.path)
            this.prepareBadges()
            if (this.items.length === 0) this.handleGoTo('no-area', this.environment === 'dtouch' ? '/dtouch/no-area' : '/players/no-area')
            else this.handleGoTo(this.items[0].mainPath, this.items[0].path)

            this.selectActiveMenu() // TMP TODO: rev
          })
      },
      selectActiveMenu () {
        this.selectedMenuItem = this.items.findIndex(x => x.mainPath === this.$route.meta.mainPath) // select menu with actual rute
      },
      handleGoTo (mainPath, path) {
        if (this.environment === 'dviewcontent') this.$store.state.app.mainPath = mainPath
        //if(mainPath !== this.$route.meta.mainPath) this.$router.push(path)
        if(path !== this.$route.path) this.$router.push(path)
      }
    }
  }
</script>

<style>
  /* .v-list-item {
    margin:10px;
    border-radius: 5px !important;
  }
  .v-list-item:hover {
    margin:10px;
    border-radius: 5px !important;
  }
  .v-list-item.active {
    background-color: rgb(33,51,83) !important;
  } */
.v-navigation-drawer__content {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
</style>

